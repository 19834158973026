import { Context } from '../context.service';
import { ExpressionExecutor } from '../exec/Executor';
import { OutputProcessor } from './OutputProcessor';

export interface BomItem {
	name: string;
	quantity: number;
	uom: string;
	reference: string;
}

export interface FieldReference {
	model: string;
	field: string;
	instanceId?: string;
}

export class BomRule {
	meta: any = {};
	dependencies: Array<FieldReference> = new Array();
	fields: Map<string, any> = new Map();

	constructor(public name: string) {
	}

	hasRequiredFields(context: Context): boolean {
		// TODO: make use of State instead
		// const ctx = context.getOutput();
		// for (let dep of this.dependencies) {
		// 	if (!ctx[dep.model])
		// 		return false;
		// 	if (!ctx[dep.model][dep.field])
		// 		return false;
		// }

		return true;
	}

	apply(context: Context): BomItem | null {
		if (!this.hasRequiredFields(context))
			return null;

		const name = this.computeField('name', context);
		const quantity = this.computeField('quantity', context);
		const uom = this.computeField('uom', context);
		const reference = this.computeField('reference', context);

		const item: BomItem = {
			name, quantity, uom, reference
		};
		return item;
	}

	computeField(fieldName: string, context: Context) {
		const field = this.fields.get(fieldName);
		if (field === undefined) {
			return null;
		}

		if (field instanceof ExpressionExecutor) {
			return field.execute(context);
		}

		return field;
	}
}

export class BomGenerator extends OutputProcessor {
	protected rules: Array<BomRule> = new Array();

	constructor(ruleset: any) {
		super();
		this.load(ruleset);
	}

	load(ruleset: any) {
		this.rules.length = 0;
		for (let ruleGroup of ruleset.rules) {
			const keys = Object.keys(ruleGroup);
			if (keys.length != 1)
				continue;
			const rule = ruleGroup[keys[0]];
			this.rules.push(this.createBomRule(keys[0], rule));
		}
	}

	apply(context: Context): void {
		const items: BomItem[] = [];
		for (let rule of this.rules) {
			const item = rule.apply(context);
			if (item) {
				items.push(item);
			}
		}

		this.onUpdate().emit(items);
	}

	private createBomRule(name: string, data: any): BomRule {
		const rule = new BomRule(name);
		rule.meta = data.meta;

		for (let ref of data.require) {
			const [ model, field ] = ref.split('.');
			rule.dependencies.push({ model: model.trim(), field: field.trim() });
		}

		for (let key of Object.keys(data)) {
			if (key === 'meta' || key === 'require')
				continue;
			rule.fields.set(key, data[key]);
		}
		return rule;
	}
}
