import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {StateResponse} from "./api/StateResponse";
import {DomainResponse} from "./api/DomainResponse";
import {PatchRequest} from "./api/PatchRequest";
import {PatchResponse} from "./api/PatchResponse";
import {HelloResponse} from "./api/HelloResponse";
import {AllocRequest} from "./api/AllocRequest";
import {AllocResponse} from "./api/AllocResponse";
import {firstValueFrom} from "rxjs";

export interface KonfooMessage {
	type: string;
	cmd: string;
	params: any;
}


@Injectable({
	providedIn: 'root'
})
export class APIService {
	private baseUrl: string = './api/v1';
	private token: string | null = null;
	private parentOrigin: string | null = null;

	constructor(private http: HttpClient) { }

	setToken(token: string): void {
		this.token = token;
	}

	setParentOrigin(origin: string) {
		this.parentOrigin = origin;
	}

	getUrl(): string {
		return this.baseUrl;
	}

	getFullUrl(): string {
		return `${window.location.href}${this.getUrl()}`;
	}

	sendMessage(data: KonfooMessage) {
		// Send internal message
		const ret = window.dispatchEvent(new CustomEvent('konfoo-message', {
			detail: data,
		}));

		// Send message the parent window embedding this application
		if (!window.parent || window.parent === window || !this.parentOrigin) {
			return;
		}
		window.parent.postMessage(data, this.parentOrigin);
	}

	hello(): Promise<HelloResponse> {
		return this.get<HelloResponse>(`state`);
	}

	getNewState(root: string | null = null, key: string | null = null): Promise<StateResponse> {
		return this.post<StateResponse>(`state`, { root, key });
	}

	getState(stateId: string): Promise<StateResponse> {
		return this.get<StateResponse>(`state/${stateId}`);
	}

	getDomain(): Promise<DomainResponse> {
		return this.get<DomainResponse>(`domain`);
	}

	patchModel(sessionId: string, delta: PatchRequest): Promise<PatchResponse> {
		return this.patch<PatchResponse>(`state/${sessionId}`, delta);
	}

	allocModel(sessionId: string, req: AllocRequest): Promise<AllocResponse> {
		return this.put<AllocResponse>(`state/${sessionId}`, req);
	}

	get<T>(url: string): Promise<T> {
		return firstValueFrom(this.http
			.get<T>(`${this.baseUrl}/${url}`, { headers: this.getHeaders(), withCredentials: true }));
	}

	text(url: string): Promise<any> {
		return firstValueFrom(this.http
			.get(`${this.baseUrl}/${url}`, {
				headers: this.getHeaders(),
				withCredentials: true,
				responseType: 'text'
			}));
	}

	post<T>(url: string, body: any): Promise<T> {
		return firstValueFrom(this.http
			.post<T>(`${this.baseUrl}/${url}`, body, { headers: this.getHeaders(), withCredentials: true }));
	}

	put<T>(url: string, body: any): Promise<T> {
		return firstValueFrom(this.http
			.put<T>(`${this.baseUrl}/${url}`, body, { headers: this.getHeaders(), withCredentials: true }));
	}

	delete(url: string): Promise<any> {
		return firstValueFrom(this.http
			.delete(`${this.baseUrl}/${url}`, { headers: this.getHeaders(), withCredentials: true }));
	}

	patch<T>(url: string, body: any): Promise<T> {
		return firstValueFrom(this.http
			.patch<T>(`${this.baseUrl}/${url}`, body, { headers: this.getHeaders(), withCredentials: true}));
	}

	head(url: string): Promise<any> {
		return firstValueFrom(this.http
			.head(`${this.baseUrl}/${url}`, { headers: this.getHeaders(), withCredentials: true }));
	}

	private getHeaders(): HttpHeaders {
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json');
		if (this.token && this.token.length > 0) {
			headers = headers.set('Authorization', `Bearer ${this.token}`);
		}
		return headers;
	}
}
