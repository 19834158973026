import { EventEmitter } from '@angular/core';
import { Context } from '../context.service';

export abstract class OutputProcessor {
	private emitterUpdate: EventEmitter<any> = new EventEmitter();

	constructor() {}

	abstract apply(context: Context): void;

	onUpdate() {
		return this.emitterUpdate;
	}
}
