import {EventEmitter, Injectable} from '@angular/core';
import {DataLoaderService, DataSourceType, YamlData} from './data-loader.service';
import {Domain} from './domain/Domain';
import {Context} from './context.service';
import {Node} from './nodes/Node';
import {BomGenerator} from './processors/BomGenerator';
import {OutputProcessor} from './processors/OutputProcessor';
import {APIService} from "./api.service";
import {SceneGraph} from "./scene/SceneGraph";
import {DomainResponse} from "./api/DomainResponse";
import {StateResponse} from "./api/StateResponse";

@Injectable({
	providedIn: 'root'
})
export class ConfiguratorService {
	private standaloneMode: boolean = false;
	private clientId: string | null = null;
	private domainSource: string = '';
	private domainData: any | null = null;

	private logicSource: string = '';
	private logicData: any | null = null;

	private bomSource: string = '';

	private domain: Domain | null = null;
	private scene: SceneGraph | null = null;

	private outputProcessors: OutputProcessor[] = [];

	private onSourceLoadedEmitter: EventEmitter<{ domain: string; logic: string; bom?: string }> = new EventEmitter();
	private onReadyEmitter: EventEmitter<void> = new EventEmitter(); // UI is ready
	private onLoadedEmitter: EventEmitter<Node> = new EventEmitter(); // Configurator state has loaded fully
	private onPromptRootModelEmitter: EventEmitter<() => void> = new EventEmitter(); // Prompt for root model select
	private onOutputProcessorsUpdatedEmitter: EventEmitter<OutputProcessor[]> = new EventEmitter();

	// public logicSourceProvider: (() => string) | null = null;
	// public domainSourceProvider: (() => string) | null = null;
	public needsRebuild: boolean = false;
	public building: boolean = false;

	constructor(
		private loader: DataLoaderService,
		private api: APIService,
		private context: Context)
	{
		this.standaloneMode = window.parent === window;
	}

	isStandalone(): boolean {
		return this.standaloneMode;
	}

	async load(clientId: string | null, logicFile: string, sessionId: string | null): Promise<void> {
		this.clientId = clientId;
		this.domainData = null;
		this.logicData = null;
		this.context.reset();

		console.log('[+] Loading domain data');
		this.domainData = await this.api.getDomain();

		console.log('[+] Loading scene graph');
		const logicData: YamlData = await this.loader.load(logicFile, DataSourceType.LOGIC);
		this.logicSource = logicData.source;
		this.logicData = logicData.data;

		this.onSourceLoadedEmitter.emit({ domain: this.domainSource, logic: this.logicSource, bom: this.bomSource });

		console.log('[+] Domain: build model definitions');
		this.domain = new Domain(this.domainData, this.context);
		this.context.setDomain(this.domain);

		this.onReadyEmitter.emit();

		if (sessionId) {
			console.log(`[+] Loading session: ${sessionId}`);
			try {
				const state = await this.api.getState(sessionId);
				this.context.load(state);
				this.build(state);
			}
			catch (e) {
				throw new Error('Could not load session');
			}
			return;
		}

		const roots = this.domain.getRoots();
		if (roots.length === 1) {
			this.domain.setRoot(roots[0].getName());
			if (!this.domain.isValid()) {
				throw new Error('Domain has no root');
			}
			await this.loadNewState();
			return;
		}

		// Starts root model selection flow
		await this.promptForRootModel();

		// If we reached here the domain should be valid
		await this.loadNewState();
	}

	async loadNewState() {
		const rootModel = this.getDomain().getRootName();
		console.log('[+] Creating a new session: %s', rootModel);
		try {
			const state = await this.api.getNewState(rootModel, this.clientId);
			this.context.load(state);
			this.build(state);
		}
		catch (e: any) {
			throw new Error(`Could not create new session: ${e.statusText || e.toString()}`);
		}
	}

	build(state: StateResponse) {
		if (!this.domainData || !state || !this.logicData) {
			console.warn(`Configurator build prevented:
				domain - ${!!this.domainData ? 'OK' : 'Not OK'}
				state - ${!!state ? 'OK' : 'Not OK'}
				graph - ${!!this.logicData ? 'OK' : 'Not OK'}`);
			return;
		}

		if (!this.domain) {
			throw new Error('Build called without Domain');
		}

		console.log('[+] Begin build');
		this.building = true;
		this.needsRebuild = false;

		console.log('[+] Domain: creating model instances');
		for (let modelId in state.by_id) {
			const instance = this.domain.deserialize(state.by_id[modelId]);
			this.context.registerModelInstance(instance);
		}
		console.log('[+] Domain: OK');

		console.log('[+] Scene graph: Creating nodes...');
		this.scene = new SceneGraph(this.domain, this.context);
		this.context.setSceneGraph(this.scene);
		this.scene.load(this.logicData);
		console.log('[+] Scene graph: OK');

		this.building = false;
		console.log('[+] Emitting: onLoaded');
		this.onLoadedEmitter.emit(this.scene.root());
	}

	onSourceLoaded(): EventEmitter<{ domain: string; logic: string; }> {
		return this.onSourceLoadedEmitter;
	}

	onReady(): EventEmitter<void> {
		return this.onReadyEmitter;
	}

	onLoaded(): EventEmitter<Node> {
		return this.onLoadedEmitter;
	}

	onPromptRootModel(): EventEmitter<() => void> {
		return this.onPromptRootModelEmitter;
	}

	onOutputProcessorsUpdated(): EventEmitter<OutputProcessor[]> {
		return this.onOutputProcessorsUpdatedEmitter;
	}

	getDomain(): Domain {
		if (!this.domain)
			throw new Error('Domain not created');
		return this.domain;
	}

	getScene(): SceneGraph {
		if (!this.scene)
			throw new Error('SceneGraph not created');
		return this.scene;
	}

	getDomainSource(): string {
		return this.domainSource;
	}

	getLogicSource(): string {
		return this.logicSource;
	}

	// Rebuilds from editor content
	rebuild() {
		console.warn('/!\\ Rebuilding disabled');
		// console.log('[+] Rebuilding...');
		//
		// if (this.domainSourceProvider)
		// 	this.domainSource = this.domainSourceProvider();
		// if (this.logicSourceProvider)
		// 	this.logicSource = this.logicSourceProvider();
		//
		// this.build(
		// 	this.loader.parseYAML(this.domainSource, DataSourceType.DOMAIN),
		// 	this.loader.parseYAML(this.logicSource, DataSourceType.LOGIC));
	}

	async addBomGenerator(bomRulesPath: string) {
		const bomRules: YamlData = await this.loader.load(bomRulesPath, DataSourceType.BOM);
		this.bomSource = bomRules.source;
		this.onSourceLoadedEmitter.emit({ domain: this.domainSource, logic: this.logicSource, bom: this.bomSource });
		this.addOutputProcessor(new BomGenerator(bomRules.data));
	}

	addOutputProcessor(processor: OutputProcessor) {
		this.outputProcessors.push(processor);
		this.onOutputProcessorsUpdatedEmitter.emit(this.outputProcessors);
	}

	getOutputProcessors(): OutputProcessor[] {
		return this.outputProcessors;
	}

	getContext(): Context {
		return this.context;
	}

	private promptForRootModel(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.onPromptRootModelEmitter.emit(() => {
				if (!this.domain) {
					return reject(new Error('Domain became unavailable after prompting for root model'));
				}

				if (!this.domain.isValid()) {
					return reject(new Error('Domain not valid after prompting for root model'));
				}
				resolve();
			});
		});
	}
}
